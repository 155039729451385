import React, { Component } from 'react'
import { InputGroup, Col, Button, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { userActions } from "../_actions";
import BackDrop from "./BackDrop";

const schema = Yup.object({
  email: Yup.string().required("required").email(),
});
class NewsletterSignup extends Component {
  constructor(props) {
    super(props);
  }
  render() {
      const { submitting } = this.props;
        return (
          <div className='nsltr'>
            {submitting ? (
              <BackDrop />
            ) : (
              <Formik
                validationSchema={schema}
                onSubmit={async (values) => {
                  var formData = new FormData();
                  formData.append("email", values.email);

                  this.props.newsletterSignup(formData);
                  resetForm();
                }}
                initialValues={{
                  email: "",
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  touched,
                  errors,
                }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group>
                      <InputGroup className="mb-3">
                        <Form.Control
                          type="text"
                          name="email"
                          id="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.email && !errors.email}
                          isInvalid={!!errors.email}
                          placeholder="Email Address"
                        />
                        <InputGroup.Append>
                          <Button variant="secondary" type="submit">
                            Submit
                          </Button>
                        </InputGroup.Append>
                      </InputGroup>
                      <Form.Control.Feedback
                        type={errors.email ? "invalid" : "valid"}
                      >
                        {errors.email ? errors.email : "Looks good!"}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form>
                )}
              </Formik>
            )}
          </div>
        );
    }
}

const mapState = (state) => ({
  submitting: state.users.submitting,
});

const actionCreators = {
  newsletterSignup: userActions.newsletterSignup,
};

export default connect(mapState, actionCreators)(NewsletterSignup);